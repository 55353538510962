<template>
  <div class="home">
    <Header />
    <b-container>
      <b-row id="grid-filter">
        <b-col cols="5">
          <b-input-group class="mt-3">
            <template #append>
              <b-input-group-text class="btn-search"
                ><b-icon icon="search"></b-icon
              ></b-input-group-text>
            </template>
            <b-form-input
              type="search"
              v-model="search"
              @change="startSearch"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <div class="actions">
            <b-dropdown
              id="dropdown-header"
              class="mt-3"
              variant="outline-primary"
              no-caret
              offset="-90"
              :disabled="search !== ''"
            >
              <template #button-content>
                <b-icon icon="funnel"></b-icon>
              </template>
              <b-dropdown-header id="ariaDescribedby">
                Filtrar por tipo:
              </b-dropdown-header>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-text>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    v-model="selected"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="plain-stacked"
                    plain
                    stacked
                  ></b-form-radio-group>
                </b-form-group>
              </b-dropdown-text>
              <b-dropdown-item aria-describedby="dropdown-header-label">
                <b-col lg="4" class="pb-2"
                  ><b-button class="aplicar-btn">Close</b-button></b-col
                >
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              variant="primary"
              class="mt-3 btn-icon"
              @click="goToAddDocument()"
            >
              <b-icon icon="plus-square" aria-hidden="true"></b-icon> ADICIONAR
            </b-button>
            <input
              type="file"
              ref="file"
              accept=".xls, .xlsx"
              @change="UploadFileProcess($event)"
              style="display: none"
            />
            <b-button
              v-if="showAddInBatch"
              variant="primary"
              class="mt-3 btn-icon"
              @click="$refs.file.click()"
            >
              <b-icon icon="plus-square" block></b-icon> ADICIONAR EM LOTE
            </b-button>
            <b-button
              v-else
              variant="danger"
              class="mt-3 btn-icon"
              @click="deletingAllSelecteds"
            >
              <b-icon icon="trash-fill" block></b-icon> REMOVER SELECIONADOS
            </b-button>
          </div>
        </b-col>
      </b-row>

      <div class="grid-div">
        <b-table-simple striped hover responsive class="grid-table" ref="table">
          <b-thead>
            <b-tr>
              <b-th>
                <b-form-checkbox
                  v-model="checkboxParent"
                  @change="toggleAllCheckboxChild(!checkAllCheckboxChild)"
                  :disabled="items.length === 0 ? true : false"
                >
                </b-form-checkbox>
              </b-th>
              <b-th>Nome</b-th>
              <b-th>Documento</b-th>
              <b-th>Tipo</b-th>
              <b-th>Fonte</b-th>
              <b-th>Motivo</b-th>
              <b-th>Observação</b-th>
              <b-th class="actions">Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody :data-selected="selected" :data-enableChecked="isChecked()">
            <b-tr
              v-for="(item, index) in items"
              :key="index"
              :class="{ CNPJ: isCNPJ(item), CPF: !isCNPJ(item) }"
              :data-index="index"
              :data-unchecked="!item.checked"
            >
              <b-th>
                <b-form-checkbox
                  v-model="item.checked"
                  @change="addItemToDeleteInGroup(index)"
                >
                </b-form-checkbox>
              </b-th>
              <b-td @click="goToViewDocument(item)" class="view">
                {{ !item.name.trim().length ? "-" : item.name }}
              </b-td>
              <b-td>{{ item.document }}</b-td>
              <b-td>
                {{
                  item.document.length === 14
                    ? (item.type = "CNPJ")
                    : (item.type = "CPF")
                }}
              </b-td>
              <b-td>{{ item.source }}</b-td>
              <b-td>{{ getReasonDescription(item.reason) }}</b-td>
              <b-td>{{ item.observation }}</b-td>
              <b-td>
                <b-row cols="2" cols-sm="4" class="text-center h5 mb-0">
                  <b-col class="mb-2">
                    <b-icon
                      icon="pencil-fill"
                      variant="primary"
                      scale="0.9"
                      class="ic-edit"
                      @click="goToEditDocument(item)"
                    >
                    </b-icon>
                  </b-col>
                  <b-col class="mb-2">
                    <b-icon
                      icon="trash-fill"
                      variant="danger"
                      scale="0.9"
                      class="ic-excluir"
                      @click="deleting(index)"
                    >
                    </b-icon>
                  </b-col>
                </b-row>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <infinite-loading
        @infinite="infiniteHandler"
        v-show="!items.length || items.length > 10"
      ></infinite-loading>
    </b-container>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "List",
  components: {
    Header,
    InfiniteLoading,
  },
  created: async function () {},
  data() {
    return {
      file: null,
      items: [],
      reasons: [],
      count: 0,
      currentPage: 0,
      modeProfile: "visualization",
      document: "",
      search: "",
      selected: "",
      showAddInBatch: true,
      countChildCheckboxChecked: 0,
      options: [
        { text: "Todos", value: "" },
        { text: "Por CPF", value: "CPF" },
        { text: "Por CNPJ", value: "CNPJ" },
      ],
      checkboxParent: false,
      checkAllCheckboxChild: false,
    };
  },
  mounted() {
    this.$root.pessoaId = sessionStorage.pessoaId;
    this.getReason();
  },
  methods: {
    getData() {
      if (this.count !== this.currentPage) {
        return this.axios.get(
          "/restrictivelist/findall?search=" +
            this.search +
            "&page=" +
            this.count +
            "&pagesize=25"
        );
      }
    },

    infiniteHandler($state) {
      this.$state = $state;
      this.count++;
      this.getData()
        .then((resp) => {
          const { restrictiveList } = resp.data;
          if (restrictiveList.length) {
            this.currentPage = this.count;
            $state.loaded();
            this.items.push(...restrictiveList);
          } else {
            $state.complete();
          }
        })
        .catch($state.complete);
    },

    goToAddDocument() {
      this.modeProfile = "adding";
      this.$router.push({
        name: "AddDocument",
        params: { modeProfile: this.modeProfile },
      });
    },

    deleting(index) {
      this.$bvModal
        .msgBoxConfirm("Essa ação não poderá ser desfeita", {
          title: "Tem certeza que deseja continuar ?",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "SIM",
          cancelTitle: "NÂO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.remove(index);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    remove(index) {
      const item = this.items[index];
      const body = {
        document: item.document,
        documentType: item.type,
        user: item.name,
      };
      this.axios
        .post("/restrictivelist/remove", body)
        .then((resposta) => {
          var result = resposta.data;
          if (result.status) {
            this.$root.$emit("notify", {
              label: "Exclusão realizada com sucesso",
              time: 5,
              type: "success",
            });
            this.items.splice(index, 1);
            this.showAddInBatch = true;
            this.checkboxParent = false;
            this.toggleAllCheckboxChild(this.checkboxParent);
            this.countChildCheckboxChecked = 0;
            document.querySelector(`[data-index="${index}"]`).remove();
          } else {
            let bodyErrors = JSON.parse(result.json);
            const message = bodyErrors.errors[0].message;
            this.$root.$emit("notify", {
              label: "Erro ao excluir = " + message,
              time: 10,
              type: "danger",
            });
            this.showAddInBatch = true;
            this.checkboxParent = false;
            this.toggleAllCheckboxChild(this.checkboxParent);
            this.countChildCheckboxChecked = 0;
          }
        })
        .catch((error) => {
          this.$root.$emit("notify", {
            label: "ERROR method name: remove => " + error,
            time: 10,
            type: "danger",
          });
        });
    },
    deletingAllSelecteds() {
      this.$bvModal
        .msgBoxConfirm("Essa ação não poderá ser desfeita", {
          title: "Tem certeza que desejas continuar com a exclusão dos dados ?",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "SIM",
          cancelTitle: "NÂO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletingAll();
          } else {
            this.showAddInBatch = true;
            this.checkboxParent = false;
            this.toggleAllCheckboxChild(this.checkboxParent);
            this.countChildCheckboxChecked = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deletingAll() {
      let countError = 0;
      let countConfirm = 0;
      let deletedDocuments = [];
      const myPromises = [];
      this.items.forEach((element) => {
        if (element.checked) {
          deletedDocuments.push(element.document);
          myPromises.push(
            this.axios.post("/restrictivelist/remove", {
              document: element.document,
              documentType: element.type,
              user: element.name,
            })
          );
        }
      });

      Promise.all(myPromises)
        .then((resposta) => {
          resposta.forEach((element) => {
            let resultado = element.data;
            if (!resultado.status) {
              countError++;
            } else {
              countConfirm++;
            }
          });
          if (countError > 0 && countConfirm === 0) {
            this.$root.$emit("notify", {
              label: "Erro ao excluir selecionados",
              time: 7,
              type: "danger",
            });
          } else if (countError > 0 && countConfirm > 0) {
            this.$root.$emit("notify", {
              label: "Nem todos os selecionados foram excluídos",
              time: 7,
              type: "warning",
            });
          } else {
            this.$root.$emit("notify", {
              label: "os dados foram excluídos com sucesso",
              time: 7,
              type: "success",
            });
            deletedDocuments.forEach((x) => {
              for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].document === x) {
                  this.items.splice(i, 1);
                }
              }
            });
          }
        })
        .catch((error) => {
          this.$root.$emit("notify", {
            label: "ERROR " + error,
            time: 7,
            type: "danger",
          });
        });

      this.showAddInBatch = true;
      this.checkboxParent = false;
      this.toggleAllCheckboxChild(this.checkboxParent);
      this.countChildCheckboxChecked = 0;
      this.startSearch();
    },
    goToEditDocument(item) {
      if (item.name.trim().length === 0) {
        this.$root.$emit("notify", {
          label:
            "Não é permitido editar as informações que foram adicionadas em lotes",
          time: 7,
          type: "warning",
        });
        return;
      } else {
        this.$root.rootObject = item;
        this.modeProfile = "edition";
        this.$router.push({
          name: "EditDocument",
          params: { document: item.document, modeProfile: this.modeProfile },
        });
      }
    },

    goToViewDocument(item) {
      this.$root.rootObject = item;
      this.$router.push({
        name: "ViewDocument",
        params: { document: item.document, modeProfile: this.modeProfile },
      });
    },

    startSearch() {
      this.count = 0;
      this.currentPage = 0;
      this.items = [];
      this.selected = "";
      this.$state.reset();
    },

    isCNPJ(item) {
      return item.document.length === 14;
    },
    getReason() {
      this.axios.get("/restrictivelist/reasons").then((response) => {
        let result = response.data.reasons;
        result.forEach((element) => {
          this.reasons.push({
            code: element.code,
            description: element.description,
          });
        });
      });
    },
    getReasonDescription(value) {
      if (value == "Analise-SF") {
        return "Analise-SF";
      } else {
        let result = this.reasons.filter((x) => x.code == value);
        return result[0].description;
      }
    },

    UploadFileProcess(event) {
      this.file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      console.log(formData);
      this.axios
        .post("/restrictivelist/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resposta) => {
          let result = resposta.data;
          console.log(result);
          if (result.status) {
            if (result.json === "") {
              this.$root.$emit("notify", {
                label: "Todos os dados do lote foram processados com sucesso",
                time: 5,
                type: "success",
              });
              this.startSearch();
            } else {
              let deniedDocuments = "";
              result.errors.forEach((element) => {
                deniedDocuments =
                  deniedDocuments +
                  "linha: " +
                  element.line +
                  " documento: " +
                  element.document +
                  "causa do erro: " +
                  element.causedError +
                  ";  ";
              });
              this.$root.$emit("notify", {
                label:
                  " Quantidade de documentos não processados = " +
                  result.errors.length +
                  ". INFORMAÇÕES EXTRA: " +
                  deniedDocuments,
                time: 20,
                type: "warning",
              });
              this.startSearch();
            }
          } else {
            this.$root.$emit("notify", {
              label: result.json,
              time: 20,
              type: "danger",
            });
          }
        })
        .catch((error) => {
          this.$root.$emit("notify", {
            label: "ERROR ao Adicionar em Lote: " + error,
            time: 10,
            type: "danger",
          });
        });
    },
    isChecked() {
      if (this.items) {
        const checkeds = this.items.filter((item) => {
          return item.checked;
        });
        return !!checkeds.length;
      }
    },
    toggleAllCheckboxChild(std) {
      this.checkAllCheckboxChild = std;
      if (this.checkAllCheckboxChild) {
        this.showAddInBatch = false;
        this.countChildCheckboxChecked = this.items.length;
      } else {
        this.showAddInBatch = true;
        this.countChildCheckboxChecked = 0;
      }
      this.items = this.items.map((item) => {
        item.checked = std;
        return item;
      });
    },
    addItemToDeleteInGroup(index) {
      this.items[index].checked
        ? this.countChildCheckboxChecked++
        : this.countChildCheckboxChecked--;

      if (this.countChildCheckboxChecked === 0) {
        this.showAddInBatch = true;
        this.checkboxParent = false;
      } else {
        if (this.countChildCheckboxChecked < this.items.length) {
          this.checkboxParent = false;
          this.checkAllCheckboxChild = false;
        }
        this.showAddInBatch = false;
      }
    },
  },
};
</script>
<style scoped>
#grid-filter {
  margin-bottom: 22px;
}
.actions button,
.actions #dropdown-header {
  margin-right: 10px;
}
.btn-icon svg {
  position: relative;
  margin-right: 5px;
  top: -3px;
  height: 16px;
}
.grid-div {
  border-radius: 16px;
}
.grid-div .table thead th {
  border-top: none;
}
.grid-div .table thead th {
  border-right: solid 1px #dee2e6;
}
.grid-table {
  border: solid;
  border-width: 1px;
  border-color: #dadada;
  border-radius: 15px;
}
.grid-table .actions {
  width: 100px;
}
.view:hover {
  color: #007bff;
  cursor: pointer;
}
.aplicar-btn {
  background-color: #1e2a4f;
}
.input-group-append :hover {
  cursor: pointer;
}
[data-selected="CNPJ"] .CPF {
  display: none;
}
[data-selected="CPF"] .CNPJ {
  display: none;
}
.ic-edit,
.ic-excluir:hover {
  cursor: pointer;
}
[data-enableChecked="true"] [data-unchecked="true"] {
  opacity: 0.3;
}
</style>
